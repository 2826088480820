/* -------------------------------------------------------------------------- */
/*                                   Modules                                  */
/* -------------------------------------------------------------------------- */

// Colors
@use './_colors.scss';

// Fonts
@use './_fonts.scss';

// Mixins
@use './_mixins.scss';

/* -------------------------------------------------------------------------- */
/*                               Global variables                             */
/* -------------------------------------------------------------------------- */

/* ******************************** BOX MODEL ******************************* */

// Content padding
$large-desktop-y-padding: 30px;
$large-desktop-x-padding: 270px;

$medium-desktop-y-padding: 30px;
$medium-desktop-x-padding: 116px;

$small-desktop-y-padding: 30px;
$small-desktop-x-padding: 65px;

// Footer
$footer-height-mobile: 4vh;
$footer-height-landscape: 12vh;
$footer-height-desktop: 7vh;

// Nav bar
$nav-height-standard: 75px;

// Modal
$modal-dimension-mobile-small: 80vw;
$modal-dimension-mobile: 332px;
$modal-dimension-desktop: 374px;

// Card
$left-card-width-mobile: 90%;

%field-text-style {
  font-family: fonts.$helvetica-light-regular;
  font-weight: 400;
  line-height: mixins.toRem(22);
  font-size: mixins.toRem(12) !important;
  color: colors.$cr-black-2;
}

%field-style {
  height: 49px;
  border: 1px solid colors.$cr-black-3 !important;
  padding: {
    left: 17px !important;
    right: 17px !important;
  }
  border-radius: 8px !important;
  background-color: transparent;
  @extend %field-text-style;

  &:hover {
    box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
  }

  &::placeholder {
    color: colors.$cr-black-1;
  }
}

%disabled-field-style {
  border-color: colors.$cr-grey-5 !important;
}

%invalid-input-style {
  border-color: colors.$cr-red-1 !important;
}
